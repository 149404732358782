import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import HorizontalScrollText from './HorizontalScroll';

function AccessTokenModal({
  isOpen, onClose, loading = false, accessToken = undefined,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-gray-800 opacity-50"
        onClick={onClose}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            onClose();
          }
        }}
        tabIndex={-1}
        aria-hidden="true"
      />

      <div className="bg-white rounded-lg p-6 w-96 relative z-10">
        <h2 className="text-lg font-semibold mb-4">Access Token</h2>
        {loading && <ClipLoader /> }
        {
          accessToken && (
            <div className="mt-4 flex gap-3 flex-col">
              <HorizontalScrollText content={accessToken} />
              <button type="submit" onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg w-1/4 ml-auto">
                Close
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default AccessTokenModal;
