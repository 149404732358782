import React, { useState, useEffect } from 'react';
import { useOrganization } from '@clerk/clerk-react';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useFetch } from './api';
import DeleteObjectModal from './DeleteObjectModal';

LicenseInfo.setLicenseKey(
  'cfb90503f538324ac26942148034f3d4Tz05NzAwOSxFPTE3NTY0MDY1NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

function ApiKeys() {
  const { organization } = useOrganization();
  const [loadingApiKeys, setLoadingApiKeys] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isDeletingApiKey, setIsDeletingApiKey] = useState(false);
  const [isGeneratingApiKey, setIsGeneratingApiKey] = useState(false);
  const [apiKeyCopied, setApiKeyCopied] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
  const [showEditNicknameConfirmation, setShowEditNicknameConfirmation] = useState(false);

  const trackstar = useFetch();

  const copyToClipboard = (content) => {
    const textArea = document.createElement('textarea');
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setApiKeyCopied(content);
    setTimeout(() => {
      setApiKeyCopied('');
    }, 1500); // Reset the copied state after 1.5 seconds
  };

  useEffect(() => {
    if (organization) {
      setLoadingApiKeys(true);
      trackstar('internal/api-keys').then((response) => {
        const { data } = response;
        setApiKeys(data);
        setLoadingApiKeys(false);
      });
    }
  }, []);

  const handleCreateApiKey = () => {
    setIsGeneratingApiKey(true);
    trackstar('internal/api-keys', 'POST').then((response) => {
      const apiKey = response;
      setApiKeys((prevApiKeys) => [...prevApiKeys, apiKey]);
      setIsGeneratingApiKey(false);
      setShowCreateConfirmation(true);
    });
  };
  const handleCloseDeleteModal = (cancelled) => {
    if (!cancelled) {
      setShowDeleteConfirmation(true);
    }
    setDeleteModalOpen(false);
    setRowToDelete(null);
  };

  const handleDeleteApiKey = (apiKey) => {
    setIsDeletingApiKey(true);
    trackstar(`internal/api-keys/${apiKey}`, 'DELETE').then(() => {
      setApiKeys(
        (prevApiKeys) => prevApiKeys.filter((prevApiKey) => prevApiKey.api_key !== apiKey),
      );
      handleCloseDeleteModal(false);
      setIsDeletingApiKey(false);
    });
  };

  const handleOpenDeleteModal = (row) => {
    setDeleteModalOpen(true);
    setRowToDelete(row);
  };

  const handleEditNickname = (apiKey) => {
    const nickname = prompt('Enter a new nickname for this API key:');
    if (nickname) {
      trackstar(`internal/api-keys/${apiKey}`, 'PATCH', { nickname }).then(() => {
        setApiKeys(
          (prevApiKeys) => prevApiKeys.map((prevApiKey) => {
            if (prevApiKey.api_key === apiKey) {
              return { ...prevApiKey, nickname };
            }
            return prevApiKey;
          }),
        );
        setShowEditNicknameConfirmation(true);
      });
    }
  };

  const columns = [{
    field: 'api_key',
    headerName: 'API Key',
    flex: 1,
    renderCell: (params) => (
      <div className="flex items-center w-11/12 justify-between">
        <span>{'*'.repeat(10) + params.value.slice(-10)}</span>
        <button
          type="button"
          onClick={() => copyToClipboard(params.row.api_key)}
          className="px-4 py-2 bg-trackstarAllDark text-white rounded-lg"
        // there's a few px overflorw to the right on some screens. do -2 to be safe
          style={{ position: 'sticky', right: -2, top: 0 }}
        >
          {apiKeyCopied === params.row.api_key ? 'Copied!' : 'Copy Key'}
        </button>
      </div>
    ),
  }, {
    field: 'nickname',
    headerName: 'Nickname',
    flex: 1,
    renderCell: (params) => (
      <div className="flex items-center w-3/4 justify-between">
        <span>{params.value ? params.value : 'No Nickname'}</span>
        <button
          type="button"
          onClick={() => handleEditNickname(params.row.api_key)}
        >
          <img src="/edit.png" alt="edit" className="h-5 w-5" />
        </button>
      </div>
    ),
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    flex: 1,
    renderCell: (params) => (
      <span>{new Date(params.value).toLocaleString()}</span>
    ),
  },
  {
    field: 'delete',
    width: 20,
    headerName: '',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    headerClassName: 'column-header-no-border',
    renderCell: (params) => (
      <button
        type="button"
        onClick={() => handleOpenDeleteModal(params.row.api_key)}
        onKeyDown={() => handleOpenDeleteModal(params.row.api_key)}
      >
        <img src="/trash.png" alt="delete" className="h-5 w-5" />
      </button>
    ),
  }];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">API Keys</h1>
        <button
          type="button"
          onClick={handleCreateApiKey}
          className="bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
        >
          Create API Key
        </button>
      </div>
      {
        showDeleteConfirmation && (
          <div className="flex items-center justify-center bg-green-100 px-4 py-3 rounded-lg">
            <p className="text-green-700 font-semibold">API Key successfully deleted!</p>
          </div>
        )
      }
      {
        showCreateConfirmation && (
          <div className="flex items-center justify-center bg-green-100 px-4 py-3 rounded-lg">
            <p className="text-green-700 font-semibold">API Key successfully created!</p>
          </div>
        )
      }
      {
        showEditNicknameConfirmation && (
          <div className="flex items-center justify-center bg-green-100 px-4 py-3 rounded-lg">
            <p className="text-green-700 font-semibold">Nickname successfully updated!</p>
          </div>
        )
      }
      <DataGridPro
        rows={apiKeys}
        columns={columns}
        getRowId={(row) => row.api_key}
        pageSizeOptions={[]}
        autoHeight
        pagination
        loading={loadingApiKeys || isDeletingApiKey || isGeneratingApiKey}
        rowHeight={50}
        className="shadow-sm"
        sortModel={[{ field: 'created_at', sort: 'desc' }]}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F5F5F5',
            borderRadius: '0px !important',
            fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            fontWeight: '400',
            fontSize: '13px',
            color: '#6b7280',
          },
          '& .column-header-no-border .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          borderRadius: '0px !important',
          '& .MuiDataGrid-cell': {
            fontSize: '13px',
            fontWeight: '400',
            fontFamily: 'ui-sans-serif, system-ui, sans-serif',
          },
        }}
      />
      <DeleteObjectModal
        isOpen={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteApiKey}
        isLoading={isDeletingApiKey}
        row={rowToDelete}
        objectType="API Key"
      />
    </div>
  );
}

export default ApiKeys;
