import React, { useEffect, useState } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import ClipLoader from 'react-spinners/ClipLoader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { useFetch } from './api';
import { renderCellExpand } from './DataGridCellExpander';
import { useEffectAfterInitialRender } from './utils';

LicenseInfo.setLicenseKey(
  'cfb90503f538324ac26942148034f3d4Tz05NzAwOSxFPTE3NTY0MDY1NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);
dayjs.extend(utc);

function ApiLogPage(props) {
  const trackstar = useFetch();
  const [logRows, setLogRows] = useState([]);
  const [logsByStatus, setLogsByStatus] = useState([]);
  const [bars, setBars] = useState([]);
  const [logsByEndpoint, setLogsByEndpoint] = useState([]);
  const [pieColors, setPieColors] = useState([]);
  const [dateFilter, setDateFilter] = useState('7d');
  const [loadingLogRows, setLoadingLogRows] = useState(false);
  const [loadingLogsByStatus, setLoadingLogsByStatus] = useState(false);
  const [loadingLogsByEndpoint, setLoadingLogsByEndpoint] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [totalAPICalls, setTotalAPICalls] = useState(0);
  const [loadingTotalAPICalls, setLoadingTotalAPICalls] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const [quickFilterSearchValue, setQuickFilterSearchValue] = useState('');
  const [connectionFilter, setConnectionFilter] = useState(null);

  const gridApiRef = useGridApiRef();

  const { connectionData, loadingConnectionData } = props;

  const statusShadesMap = {
    200: '#12b76a',
    400: '#f04438',
    401: '#ff9999',
    403: '#ff6666',
    404: '#ff3333',
    422: '#cc0000',
    429: '#d9534f',
    501: '#89cff0',
  };

  const getTimeBreakdown = (end, start) => {
    let timeBreakdown;
    let tickFormatter;
    if (end.diff(start, 'months') >= 6) {
      timeBreakdown = 'month';
      tickFormatter = (value) => dayjs.utc(value).format('MMM YYYY');
    } else if (end.diff(start, 'days') >= 5) {
      timeBreakdown = 'day';
      tickFormatter = (value) => dayjs.utc(value).format('MMMM D');
    } else if (end.diff(start, 'hours') >= 12) {
      timeBreakdown = 'hour';
      tickFormatter = (value) => dayjs.utc(value).format('HH:00');
    } else {
      timeBreakdown = 'minute';
      tickFormatter = (value) => dayjs.utc(value).format('HH:mm');
    }
    return { timeBreakdown, tickFormatter };
  };

  const getBarChartTooltip = ({ payload: entries, label }) => {
    if (entries && entries.length) {
      const formatter = getTimeBreakdown(endDate, startDate).tickFormatter;
      const formattedLabel = formatter(label);
      const entryFormatter = (entry) => `${entry.dataKey}: ${entry.payload[entry.dataKey]}`;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <span>{formattedLabel}</span>
          {entries.map((entry) => (
            <div style={{ display: 'flex', alignItems: 'center', color: entry.color }}>
              <span
                style={{
                  display: 'inline-block',
                  marginRight: '10px',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: entry.color,
                }}
              />
              <span className="font-medium text-sm">
                {entryFormatter(entry)}
              </span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const getPieKey = (entry) => `${entry.request_method} ${entry.request_path}: ${entry.count}`;
  const renderPieChartLegend = ({ payload: entries }) => (
    <ul>
      {entries.sort((a, b) => a.payload.count - b.payload.count).reverse().map((entry) => (
        <li key={getPieKey(entry.payload)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                display: 'inline-block',
                marginRight: '10px',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: entry.color,
              }}
            />
            <p className="font-medium text-gray-600 text-sm">{getPieKey(entry.payload)}</p>
          </div>
        </li>
      ))}
    </ul>
  );
  const getPieChartTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const pieKey = getPieKey(payload[0].payload);
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                display: 'inline-block',
                marginRight: '10px',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: pieColors[pieKey],
              }}
            />
            <p className="font-medium text-gray-600 text-sm">{pieKey}</p>
          </div>
        </div>
      );
    }
    return null;
  };
  const getPieColors = (data) => {
    const lightPurple = { r: 229, g: 204, b: 255 }; // Lightest purple
    const deepPurple = { r: 127, g: 86, b: 217 }; // Deepest purple
    const numColors = data.length;
    const sortedData = data.sort((a, b) => a.count - b.count);
    const shades = {};
    for (let i = 0; i < numColors; i += 1) {
      const factor = i / ((numColors - 1) || 1); // Evenly spaced factor
      const r = Math.round(lightPurple.r + factor * (deepPurple.r - lightPurple.r));
      const g = Math.round(lightPurple.g + factor * (deepPurple.g - lightPurple.g));
      const b = Math.round(lightPurple.b + factor * (deepPurple.b - lightPurple.b));
      const pieKey = getPieKey(sortedData[i]);
      shades[pieKey] = `rgb(${r}, ${g}, ${b})`;
    }
    return shades;
  };

  const getBreakdownData = () => {
    setLoadingLogsByStatus(true);
    setLoadingLogsByEndpoint(true);
    const startDateUTC = startDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
    let endDateUTC = endDate.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    if (dateFilter === 'custom') {
      endDateUTC = endDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    const { timeBreakdown } = getTimeBreakdown(endDate, startDate);

    let statusBreakdown = `internal/api-logs-breakdown?start_date=${startDateUTC}&end_date=${endDateUTC}&groups=${timeBreakdown},status_code`;
    if (connectionFilter) {
      statusBreakdown += `&connection_id=${connectionFilter}`;
    }
    trackstar(statusBreakdown, 'GET').then((response) => {
      const { data } = response;
      const formatted = {};
      const statusCodes = [];
      const barsFromData = [];
      for (let i = 0; i < data.length; i += 1) {
        const entry = data[i];
        if (formatted[entry.request_time] == null) {
          formatted[entry.request_time] = {};
        }
        formatted[entry.request_time][entry.status_code] = entry.count;
        if (!statusCodes.includes(entry.status_code) && entry.status_code !== 500) {
          statusCodes.push(entry.status_code);
          const bar = <Bar key={entry.status_code} dataKey={entry.status_code} stackId="a" fill={statusShadesMap[entry.status_code] || 'purple'} />;
          barsFromData.push(bar);
        }
      }
      const sortedKeys = Object.keys(formatted).sort((a, b) => dayjs(a).unix() - dayjs(b).unix());
      const sortedData = [];
      for (let i = 0; i < sortedKeys.length; i += 1) {
        const entry = formatted[sortedKeys[i]];
        entry.request_time = sortedKeys[i];
        sortedData.push(entry);
      }
      setLogsByStatus(sortedData);
      setBars(barsFromData);
      setLoadingLogsByStatus(false);
    });
    let endpointBreakdown = `internal/api-logs-breakdown?start_date=${startDateUTC}&end_date=${endDateUTC}&groups=request_path,request_method`;
    if (connectionFilter) {
      endpointBreakdown += `&connection_id=${connectionFilter}`;
    }
    trackstar(endpointBreakdown, 'GET').then((response) => {
      const { data } = response;
      setLogsByEndpoint(data);
      setPieColors(getPieColors(data));
      setLoadingLogsByEndpoint(false);
    });
  };

  const getAPILogRows = () => {
    setLoadingLogRows(true);
    setLoadingTotalAPICalls(true);
    const startDateUTC = startDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
    let endDateUTC = endDate.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    if (dateFilter === 'custom') {
      endDateUTC = endDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
    let url = `internal/api-logs?start_date=${startDateUTC}&end_date=${endDateUTC}&limit=${paginationModel.pageSize}&page_token=${paginationModel.page + 1}`;
    if (connectionFilter) {
      url += `&connection_id=${connectionFilter}`;
    }
    trackstar(url, 'GET').then((response) => {
      const { data } = response;
      setLogRows(data ? data[0] : []);
      setLoadingLogRows(false);
      setRowCountState(data ? data[2] : 0);
      setTotalAPICalls(data ? data[2] : 0);
      setLoadingTotalAPICalls(false);
    });
  };

  const updateConnectionFilter = (value) => {
    setConnectionFilter(value === 'all' ? null : value);
  };

  useEffect(() => {
    getAPILogRows(true, false);
    getBreakdownData();
  }, [startDate, endDate, connectionFilter]);

  useEffectAfterInitialRender(() => {
    getAPILogRows();
  }, [paginationModel]);

  const updateSearchValue = (newValue) => setTimeout(() => {
    gridApiRef.current.setQuickFilterValues(
      newValue.split(' ').filter((word) => word !== ''),
    );
  }, 300);

  const handleQuickFilterSearchChange = (event) => {
    if (event.target.value !== undefined) {
      setQuickFilterSearchValue(event.target.value);
      updateSearchValue(event.target.value);
    }
  };

  const colHeaders = [
    {
      field: 'request_path',
      headerName: 'Request Path',
      width: 200,
      flex: 1,
    },
    {
      field: 'integration_name',
      headerName: 'Integration Name',
      width: 200,
      valueFormatter: (params) => {
        const { value } = params;
        if (value == null) {
          return null;
        }
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      renderCell: (params) => {
        const { value } = params;
        let logo = `https://trackstarlogosbucket.s3.amazonaws.com/${value}.png`;
        if (value == null) {
          return <p>None</p>;
        }
        if (value === 'sandbox') {
          logo = 'ts_logo_2024.png';
        }
        return (
          <div className="flex gap-2 items-center">
            <img src={logo} alt={`${value} logo`} className="h-5 w-5" />
            <p>{value.charAt(0).toUpperCase() + value.slice(1)}</p>
          </div>
        );
      },
      flex: 1,
    }, {
      field: 'request_time',
      headerName: 'Request Time',
      width: 250,
      flex: 1,
      filterable: false,
    }, {
      field: 'request_method',
      headerName: 'Request Method',
      width: 200,
      flex: 1,
    },
    {
      field: 'status_code',
      headerName: 'Status Code',
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const className = params.value >= 200 && params.value < 300 ? 'border-trackstarOKDark text-trackstarOKDark' : 'border-trackstarBadDark text-trackstarBadDark';
        return <button className={`px-3 py-1 border rounded-2xl ${className}`} type="button">{params.value}</button>;
      },

    },
    {
      field: 'connection_id',
      headerName: 'Connection ID',
      width: 200,
      flex: 1,
    },
    {
      field: 'query_params',
      headerName: 'Query Params',
      width: 200,
      flex: 1,
      filterable: false,
      renderCell: renderCellExpand,
      valueFormatter: (params) => {
        const { value } = params;
        if (value == null) {
          return null;
        }
        const formattedParams = [];
        Object.keys(value).forEach((key) => {
          let operator = '=';
          Object.keys(value[key]).forEach((subKey) => {
            if (subKey === 'gte') {
              operator = '>=';
            } else if (subKey === 'lte') {
              operator = '<=';
            } else if (subKey === 'gt') {
              operator = '>';
            } else if (subKey === 'lt') {
              operator = '<';
            } else if (subKey === 'ne') {
              operator = '!=';
            } else if (subKey === 'eq') {
              operator = '=';
            } else {
              operator = subKey;
            }
            const formattedValue = `${key}${operator}${value[key][subKey]}`;
            formattedParams.push(formattedValue);
          });
        });
        return formattedParams.join(', ');
      },
    },
    {
      field: 'path_param',
      headerName: 'Path Param',
      width: 200,
      flex: 1,
      filterable: false,
    },
    {
      field: 'error',
      headerName: 'Error',
      width: 200,
      flex: 1,
      filterable: false,
      renderCell: renderCellExpand,
    },
  ];

  return (
    <div className="flex flex-col gap-8 p-5 w-10/12 h-screen">
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold leading-9 whitespace-nowrap">
          API Activity Log
        </h1>
        <div className="flex gap-8 justify-end">
          <div className="flex">
            <button
              type="button"
              className={`${dateFilter === '12m' ? 'bg-gray-300' : 'bg-white'} w-24 h-10 hover:bg-gray-300 py-1 px-1 rounded-l-md border border-gray-300 text-sm font-medium text-gray-600`}
              onClick={() => {
                setDateFilter('12m');
                setStartDate(dayjs().utc().subtract(12, 'months'));
              }}
            >
              12 months
            </button>
            <button
              type="button"
              className={`${dateFilter === '30d' ? 'bg-gray-300' : 'bg-white'} w-20 h-10 hover:bg-gray-300 py-1 px-1 border-t border-b border-gray-300 text-sm font-medium text-gray-600`}
              onClick={() => {
                setDateFilter('30d');
                setStartDate(dayjs().utc().subtract(30, 'days'));
              }}
            >
              30 days
            </button>
            <button
              type="button"
              className={`${dateFilter === '7d' ? 'bg-gray-300' : 'bg-white'} w-20 h-10 hover:bg-gray-300 py-1 px-1 border-t border-b border-l border-gray-300 text-sm font-medium text-gray-600`}
              onClick={() => {
                setDateFilter('7d');
                setStartDate(dayjs().utc().subtract(7, 'days'));
              }}
            >
              7 days
            </button>
            <button
              type="button"
              className={`${dateFilter === '1d' ? 'bg-gray-300' : 'bg-white'} w-24 h-10 hover:bg-gray-300 py-1 px-1 border border-gray-300 rounded-r-md text-sm font-medium text-gray-600`}
              onClick={() => {
                setDateFilter('1d');
                setStartDate(dayjs().utc().subtract(1, 'days'));
              }}
            >
              24 hours
            </button>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="w-64">
              {/* v7 mui has DateTimeRangePicker... look into that */}
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                name="allowedRange"
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '6px',
                  },
                }}
                disableFuture
                minDate={dayjs().subtract(12, 'months')}
                onChange={(newValue) => {
                  if (newValue[0] && newValue[1]) {
                    const start = newValue[0].hour(23).minute(59).second(59);
                    const end = newValue[1].hour(23).minute(59).second(59);
                    setStartDate(start);
                    setEndDate(end);
                    setDateFilter('custom');
                  }
                }}
              />
            </div>
          </LocalizationProvider>
          {loadingConnectionData ? <ClipLoader />
            : (
              <select onChange={(event) => updateConnectionFilter(event.target.value)} className="px-3 py-2 border rounded-lg focus:ring focus:ring-blue-200 h-fit w-1/6 text-ellipsis">
                <option value="all">All Connections</option>
                {connectionData?.map((connection) => (
                  <option key={connection.connection_id} value={connection.connection_id}>
                    {connection.connection_id}
                  </option>
                ))}
              </select>
            )}
        </div>
      </div>
      <div className="flex gap-8 w-full">
        <div className="shadow-sm rounded-lg p-3 border w-1/2 flex flex-col gap-2 h-64">
          <div className="flex gap-1 items-center">
            <h2 className="font-medium text-gray-600"> API Calls:</h2>
            {loadingTotalAPICalls ? <ClipLoader /> : (
              <h2 className="font-semibold text-gray-600">
                {totalAPICalls}
              </h2>
            )}
          </div>
          <hr className="border-gray-200" />
          {
              loadingLogsByStatus
                ? <ClipLoader className="m-auto" />
                : (
                  // make the width and height of the bar chart dynamic
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={logsByStatus} style={{ fontSize: '13px' }}>
                      <XAxis dataKey="request_time" tickFormatter={getTimeBreakdown(endDate, startDate).tickFormatter} />
                      <YAxis />
                      <Tooltip content={getBarChartTooltip} />
                      <Legend align="right" verticalAlign="top" iconType="circle" />
                      {bars}
                    </BarChart>
                  </ResponsiveContainer>
                )
            }
        </div>
        <div className="shadow-sm rounded-lg p-3 border w-1/2 h-64 flex flex-col gap-2">
          <h2 className="font-medium text-gray-600"> API Calls By Endpoint</h2>
          <hr className="border-gray-200" />
          {
            loadingLogsByEndpoint
              ? <ClipLoader className="m-auto" />
              : (
                <ResponsiveContainer width="100%" height="100%">

                  <PieChart style={{ fontSize: '13px' }}>
                    <Pie
                      data={logsByEndpoint}
                      dataKey="count"
                      cx="50%"
                      cy="50%"
                      stroke="none"
                    >
                      {logsByEndpoint.map((entry) => {
                        const key = getPieKey(entry);
                        const color = pieColors[key];
                        return <Cell key={key} fill={color} />;
                      })}
                    </Pie>
                    <Legend align="right" verticalAlign="top" content={renderPieChartLegend} layout="vertical" wrapperStyle={{ height: '100%', overflowY: 'auto' }} />
                    <Tooltip content={getPieChartTooltip} />
                  </PieChart>
                </ResponsiveContainer>
              )
          }
        </div>
      </div>
      <div className="flex flex-col h-full overflow-auto max-h-full">
        <div className="flex justify-between items-center border-l border-r border-t p-4 rounded-t-lg">
          <div className="flex flex-col gap-1 ">
            <p className="text-gray-600 font-medium text-sm">All Calls Made to Trackstar&apos;s API</p>
          </div>
          <div className="flex gap-1">
            <input
              type="text"
              placeholder="Search"
              className="border rounded-lg px-3 py-1"
              value={quickFilterSearchValue}
              onChange={handleQuickFilterSearchChange}
            />
          </div>
        </div>

        <DataGridPro
          rows={logRows}
          columns={colHeaders}
          loading={loadingLogRows}
          pageSizeOptions={[5]}
          pageSizes={[100]}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={rowCountState}
          apiRef={gridApiRef}
          disableColumnSelector
          disableColumnPinning
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F5F5F5',
              borderRadius: '0px !important',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              fontWeight: '400',
              fontSize: '13px',
              color: '#6b7280',
            },
            '& .column-header-no-border .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            borderRadius: '0px !important',
            '& .MuiDataGrid-cell': {
              fontSize: '13px',
              fontWeight: '400',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            },
          }}
        />
      </div>
    </div>
  );
}

export default ApiLogPage;
