import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

const ApiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export function useFetch() {
  const { getToken } = useAuth();
  const authenticatedFetch = async (path, method = 'GET', data = null, responseType = 'json') => fetch(`${ApiEndpoint}/${path}`, {
    method,
    headers: { Authorization: `Bearer ${await getToken()}`, 'Content-Type': 'application/json', 'Response-Type': responseType },
    body: data ? JSON.stringify(data) : null,
  }).then((res) => (responseType === 'json' ? res.json() : res.blob()));
  return authenticatedFetch;
}

export function useSendImage() {
  const { getToken } = useAuth();
  const sendImage = async (path, data) => axios.post(
    `${ApiEndpoint}/${path}`,
    data,
    { headers: { Authorization: `Bearer ${await getToken()}` } },
  );
  return sendImage;
}
