import React, { useState, useEffect } from 'react';
import { useOrganization, useAuth } from '@clerk/clerk-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router-dom';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GenerateSandboxModal from './GenerateSandboxModal';
import { useFetch } from './api';

function Homepage(props) {
  const navigate = useNavigate();
  const trackstar = useFetch();
  const { organization } = useOrganization();
  const { orgRole } = useAuth();

  const [sandboxGenerated, setSandboxGenerated] = useState(false);
  const [linkSetup, setLinkSetup] = useState(false);
  const [prodApiCall, setProdApiCall] = useState(false);
  const [isGenerateSandBoxModalOpen, setIsGenerateSandBoxModalOpen] = useState(false);
  const [isGeneratingSandBox, setIsGeneratingSandBox] = useState(false);
  const [apiKeys, setApiKeys] = useState(null);
  const [sandboxRegenerated, setSandboxRegenerated] = useState(false);

  const handleOpenGenerateSandBoxModal = () => {
    setIsGenerateSandBoxModalOpen(true);
  };

  const handleCloseGenerateSandBoxModal = () => {
    setIsGenerateSandBoxModalOpen(false);
  };

  const handleGenerateSandbox = () => {
    setIsGeneratingSandBox(true);
    trackstar('internal/sandbox/generate-sandbox', 'POST').then(() => {
      setIsGeneratingSandBox(false);
      setSandboxGenerated(true);
      setSandboxRegenerated(true);
    });
  };

  const { connectionData, connectionErrors, loadingConnectionData } = props;

  useEffect(() => {
    if (connectionData) {
      connectionData.forEach((connection) => {
        // If connection where integration_name = sandbox exists setSandboxGenerated to true
        if (connection.integration_name === 'sandbox') {
          setSandboxGenerated(true);
        }
        if (connection.integration_name !== 'sandbox') {
          setLinkSetup(true);
        }
        if (connection.integration_name !== 'sandbox' && connection.times_used > 0) {
          setProdApiCall(true);
        }
      });
    }
  }, [connectionData]);

  useEffect(() => {
    if (organization) {
      trackstar('internal/api-keys').then((response) => {
        const { data } = response;
        setApiKeys(data);
      });
    }
  }, []);

  return (
    <div className="flex flex-col gap-8 p-5 w-10/12">
      <h1 className="text-3xl font-semibold leading-9">
        Hello,
        {' '}
        {organization.name.charAt(0).toUpperCase() + organization.name.slice(1)}
      </h1>
      <div className="flex gap-10">
        <div className="flex flex-col shadow-sm rounded-lg p-10 w-5/12 border">
          <h1 className="text-xl font-medium pb-5"> Getting Started</h1>
          <div className="flex flex-col">
            <div className="flex flex-col py-3">
              <div className="flex gap-2">
                <img src={apiKeys ? 'completedStep.png' : 'notStartedStep.png'} alt="api step" className="h-5 w-5 mt-1" />
                <div className="flex flex-col">
                  <h2 className="text-md font-medium">Retrieve Your API Key</h2>
                  <p className="text-sm">Get an API Key to start using the Trackstar API</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-3">
              <div className="flex gap-2">
                <img src={sandboxGenerated ? 'completedStep.png' : 'notStartedStep.png'} alt="sandbox step" className="h-5 w-5 mt-1" />
                <div className="flex flex-col">
                  <h2 className="text-md font-medium">Generate Sandbox Environment</h2>
                  <p className="text-sm">Take Trackstar for a spin</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-3">
              <div className="flex gap-2">
                <img src={linkSetup ? 'completedStep.png' : 'notStartedStep.png'} alt="link step" className="h-5 w-5 mt-1" />
                <div className="flex flex-col">
                  <h2 className="text-md font-medium">Setup Trackstar Link</h2>
                  <p className="text-sm">Let Users integrate their WMS in your UI</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-3">
              <div className="flex gap-2">
                <img src={prodApiCall ? 'completedStep.png' : 'notStartedStep.png'} alt="production step" className="h-5 w-5 mt-1" />
                <div className="flex flex-col">
                  <h2 className="text-md font-medium">Make Production API Call</h2>
                  <p className="text-sm">Unlock customer WMS data in your application</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-4/12">
          <a href="https://docs.trackstarhq.com" target="_blank" rel="noreferrer" className="flex justify-between items-center shadow-sm rounded-lg p-5 border h-24 hover:scale-105">
            <h2 className="font-medium text-gray-600">Documentation</h2>
            <img src="externalLink.png" alt="external link" className="h-5 w-5" />
          </a>
          <button type="button" onClick={() => navigate('/settings/api-keys')} className="shadow-sm rounded-lg p-5 border flex justify-between items-center h-24 hover:scale-105">
            <h2 className="font-medium text-gray-600">
              Manage API Keys
            </h2>
            <img src="key.png" alt="goto" className="h-5 w-5" />
          </button>
          <button type="button" onClick={() => handleOpenGenerateSandBoxModal()} className="shadow-sm rounded-lg p-5 border flex justify-between items-center h-24 hover:scale-105">
            <h2 className="font-medium text-gray-600">
              Regenerate Sandbox Data
            </h2>
            <img src="regenerate.png" alt="regenerate" className="h-5 w-5" />
          </button>
          <button type="button" onClick={() => navigate('/settings/users')} className={`shadow-sm rounded-lg p-5 border flex justify-between h-24 items-center ${orgRole === 'admin' ? '' : 'pointer-events-none opacity-50'} hover:scale-105`}>
            <h2 className="font-medium text-gray-600">
              Manage Users
              {' '}
              {orgRole === 'admin' ? '' : '(Admin Only)'}
            </h2>
            <PersonAddAltIcon className="h-5 w-5 text-gray-400 stroke-width-0" />
          </button>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="shadow-sm rounded-lg p-5 border w-1/4 h-48 flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <img src="totalConnections.png" alt="total connections" className="h-10 w-10" />
            <input type="image" src="goto.png" alt="goto" className="h-5 w-5 hover:scale-125" onClick={() => navigate('/connections')} />
          </div>
          <h2 className="font-medium text-gray-600"> Total Connections</h2>
          {loadingConnectionData ? <ClipLoader />
            : <p className="text-3xl font-bold">{connectionData ? connectionData.length : 0}</p>}
        </div>
        <div className="shadow-sm rounded-lg p-5 border w-1/4 h-48 flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <img src="connectionIssues.png" alt="connection issues" className="h-10 w-10" />
            <input type="image" alt="goto" className="h-5 w-5 hover:scale-125" src="goto.png" onClick={() => navigate('/connections')} />
          </div>
          <h2 className="font-medium text-gray-600"> Connection Issues</h2>
          {loadingConnectionData ? <ClipLoader />
            : <p className="text-3xl font-bold">{connectionErrors}</p>}
        </div>
        <div className="shadow-sm rounded-lg p-5 border w-1/4 h-48 flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <img src="apiCalls.png" alt="total api calls" className="h-10 w-10" />
            <input type="image" alt="goto" className="h-5 w-5 hover:scale-125" src="goto.png" onClick={() => navigate('/connections')} />
          </div>
          <h2 className="font-medium text-gray-600"> Total API Calls</h2>
          <p className="text-3xl font-bold">
            {loadingConnectionData ? <ClipLoader />
              : (connectionData && connectionData.map((connection) => {
                if (connection.times_used) {
                  return connection.times_used;
                }
                return 0;
              }).reduce((a, b) => a + b, 0)) || 0}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <GenerateSandboxModal
          isOpen={isGenerateSandBoxModalOpen}
          onClose={handleCloseGenerateSandBoxModal}
          onConfirm={handleGenerateSandbox}
          loading={isGeneratingSandBox}
          completed={sandboxRegenerated}
        />
      </div>
    </div>
  );
}

export default Homepage;
