import { useEffect, useRef } from 'react';

export function useEffectAfterInitialRender(func, dependencies) {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      func();
    }
  }, dependencies);
}
