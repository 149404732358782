import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useFetch } from './api';

function SyncSettings() {
  const trackstar = useFetch();
  const [firstCrawlLookbackDays, setFirstCrawlLookbackDays] = useState(0);
  const [loadingOrgSettings, setLoadingOrgSettings] = useState(false);
  const [firstCrawlLookbackError, setFirstCrawlLookbackError] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [savingSettings, setSavingSettings] = useState(false);

  const handleLookbackDaysChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    setFirstCrawlLookbackDays(inputValue);
    setFirstCrawlLookbackError(inputValue < 30 || inputValue > 365 * 3);
    setUpdateDisabled(inputValue < 30 || inputValue > 365 * 3);
  };

  const saveSettings = () => {
    setSavingSettings(true);
    trackstar(
      'internal/org-settings',
      'POST',
      {
        first_crawl_lookback_days: firstCrawlLookbackDays,
      },
    ).then(() => setSavingSettings(false));
  };

  useEffect(() => {
    setLoadingOrgSettings(true);
    trackstar('internal/org').then((response) => {
      const { data } = response;
      setFirstCrawlLookbackDays(data.first_crawl_lookback_days);
      setLoadingOrgSettings(false);
    });
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Sync Settings</h1>
      </div>
      <div className="border p-4 bg-white rounded-md shadow-sm flex flex-col gap-6">
        <div className="flex flex-col bg-white gap-2">
          <span className="text-sm font-semibold">Lookback Days</span>
          <p className="text-sm text-gray-500 mt-1">
            The number of days that Trackstar will go back and sync data for new connections.
            <br />
            Must be between 30 and 1095 (365 * 3).
          </p>
          <div className="flex items-center">
            <div>
              {loadingOrgSettings ? <ClipLoader color="#C9B0F0" size={20} /> : (
                <input
                  type="number"
                  id="numberInput"
                  name="numberInput"
                  className={`w-16 text-center border border-gray-300 rounded py-2 mr-2 ${firstCrawlLookbackError
                    ? 'border-red-500'
                    : ''
                  }`}
                  min="30"
                  max="1095"
                  step="30"
                  value={firstCrawlLookbackDays}
                  onChange={handleLookbackDaysChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {savingSettings
          ? <ClipLoader color="#C9B0F0" size={20} />
          : (
            <button
              type="button"
              className={`px-4 py-2 h-10 w-20 rounded ${(firstCrawlLookbackError || updateDisabled) ? 'bg-gray-300 cursor-not-allowed text-gray-500' : 'bg-blue-500 text-white'}`}
              onClick={() => {
                saveSettings();
                setUpdateDisabled(true);
              }}
              disabled={firstCrawlLookbackError || updateDisabled}
            >
              Update
            </button>
          )}
      </div>
    </div>
  );
}

export default SyncSettings;
