import React, { useEffect, useState } from 'react';
import {
  createNewResource, getNewOrderDefaults, updateOrder,
} from './SandboxApi';

function CreateUpdateOrderModal({
  isOpen, onClose, accessToken, apiKey, handleCreateNewResource, existingOrder,
  handleCreateUpdateError,
}) {
  const [orderData, setOrderData] = useState(getNewOrderDefaults());
  const [loading, setLoading] = useState(false);
  const defaultLineItem = {
    product_id: 'product_id',
    sku: 'sku',
    quantity: 1,
    unit_price: 0,
    discount_amount: 0,
  };

  const handleClose = () => {
    setOrderData(getNewOrderDefaults());
    setLoading(false);
    onClose();
  };

  const handleCreateUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    if (existingOrder) {
      updateOrder(accessToken, apiKey, existingOrder.id, orderData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    } else {
      createNewResource(accessToken, apiKey, 'orders', orderData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    }
  };

  const addLineItem = () => {
    setOrderData({ ...orderData, line_items: [...orderData.line_items, defaultLineItem] });
  };

  const handleLineItemUpdate = (lineItemNum, updatedField) => (e) => {
    const newLineItems = [...orderData.line_items];
    newLineItems[lineItemNum][updatedField] = e.target.value;
    setOrderData({ ...orderData, line_items: newLineItems });
  };

  useEffect(() => {
    if (existingOrder) {
      setOrderData(existingOrder);
    } else {
      setOrderData(getNewOrderDefaults());
    }
  }, [existingOrder]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => onClose()} tabIndex={-1} aria-hidden="true" />
      <form className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-3/4" onSubmit={handleCreateUpdate}>
        <h2 className="text-2xl font-semibold pt-1 pb-0">
          {existingOrder ? 'Update' : 'Create New'}
          {' '}
          Order
        </h2>
        <div className="grid grid-cols-3 gap-2" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div key="refernceId">
            <span htmlFor="referenceId" className="block text-sm font-medium text-gray-700">Reference Id</span>
            <input
              type="text"
              id="referenceId"
              name="referenceId"
              value={orderData.reference_id}
              onChange={(e) => setOrderData({ ...orderData, reference_id: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
              required
            />
          </div>
          <div key="orderNumber">
            <span htmlFor="orderNumber" className="block text-sm font-medium text-gray-700">Order Number</span>
            <input
              type="text"
              id="orderNumber"
              name="orderNumber"
              value={orderData.order_number}
              onChange={(e) => setOrderData({ ...orderData, order_number: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
              required
            />
          </div>
          <div key="invoiceCurrencyCode">
            <span htmlFor="invoiceCurrencyCode" className="block text-sm font-medium text-gray-700">Invoice Currency Code</span>
            <input
              type="text"
              id="invoiceCurrencyCode"
              name="invoiceCurrencyCode"
              value={orderData.invoice_currency_code}
              onChange={(e) => {
                setOrderData({ ...orderData, invoice_currency_code: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
              required
            />
          </div>
          <div key="channel">
            <span htmlFor="channel" className="block text-sm font-medium text-gray-700">Channel</span>
            <input
              type="text"
              id="channel"
              name="channel"
              value={orderData.channel}
              onChange={(e) => setOrderData({ ...orderData, channel: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
              required
            />
          </div>
          <div key="shippingMethod">
            <span htmlFor="shippingMethod" className="block text-sm font-medium text-gray-700">Shipping Method</span>
            <input
              type="text"
              id="shippingMethod"
              name="shippingMethod"
              value={orderData.shipping_method}
              onChange={(e) => setOrderData({ ...orderData, shipping_method: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div className="flex flex-col">
            <span htmlFor="totalPrice" className="block text-sm font-medium text-gray-700">Total Price</span>
            <input
              type="text"
              id="totalPrice"
              name="totalPrice"
              value={orderData.total_price}
              onChange={(e) => setOrderData({ ...orderData, total_price: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="totalTax">
            <span htmlFor="totalTax" className="block text-sm font-medium text-gray-700">Total Tax</span>
            <input
              type="text"
              id="totalTax"
              name="totalTax"
              value={orderData.total_tax}
              onChange={(e) => setOrderData({ ...orderData, total_tax: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="totalDiscount">
            <span htmlFor="totalDiscount" className="block text-sm font-medium text-gray-700">Total Discount</span>
            <input
              type="text"
              id="totalDiscount"
              name="totalDiscount"
              value={orderData.total_discount}
              onChange={(e) => setOrderData({ ...orderData, total_discount: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="totalShipping">
            <span htmlFor="totalShipping" className="block text-sm font-medium text-gray-700">Total Shipping</span>
            <input
              type="text"
              id="totalShipping"
              name="totalShipping"
              value={orderData.total_shipping}
              onChange={(e) => setOrderData({ ...orderData, total_shipping: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <hr className="col-span-3" style={{ borderTop: '1px solid #bbb', marginTop: '12px' }} />
          <span className="col-span-3 block text-sm font-medium text-gray-700">Ship to Address</span>
          <div key="address1">
            <span htmlFor="address1" className="block text-sm font-medium text-gray-700">Address 1</span>
            <input
              type="text"
              id="address1"
              name="address1"
              value={orderData.ship_to_address.address1}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, address1: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="adderss2">
            <span htmlFor="address2" className="block text-sm font-medium text-gray-700">Address 2</span>
            <input
              type="text"
              id="address2"
              name="address2"
              value={orderData.ship_to_address.address2}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, address2: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="fullName">
            <span htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</span>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={orderData.ship_to_address.full_name}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, full_name: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="company">
            <span htmlFor="company" className="block text-sm font-medium text-gray-700">Company</span>
            <input
              type="text"
              id="company"
              name="company"
              value={orderData.ship_to_address.company}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, company: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="city">
            <span htmlFor="city" className="block text-sm font-medium text-gray-700">City</span>
            <input
              type="text"
              id="city"
              name="city"
              value={orderData.ship_to_address.city}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, city: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="state">
            <span htmlFor="state" className="block text-sm font-medium text-gray-700">State</span>
            <input
              type="text"
              id="state"
              name="state"
              value={orderData.ship_to_address.state}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, state: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="postalCode">
            <span htmlFor="postalCode" className="block text-sm font-medium text-gray-700">Postal Code</span>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={orderData.ship_to_address.postal_code}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, postal_code: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="country">
            <span htmlFor="country" className="block text-sm font-medium text-gray-700">Country</span>
            <input
              type="text"
              id="country"
              name="country"
              value={orderData.ship_to_address.country}
              onChange={(e) => {
                setOrderData({
                  ...orderData,
                  ship_to_address: { ...orderData.ship_to_address, country: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <hr className="col-span-3" style={{ borderTop: '1px solid #bbb', marginTop: '12px' }} />
          <span className="col-span-3 block text-sm font-medium text-gray-700">Line Items</span>
          {orderData.line_items.map((entry, index) => (
            <>
              <hr className="col-span-3" style={{ borderTop: '1px dashed #bbb', marginTop: '12px' }} />
              <span className="col-span-3 block text-sm font-medium text-gray-700">
                Line Item #
                {index + 1}
              </span>
              <div key="productId">
                <span htmlFor="productId" className="block text-sm font-medium text-gray-700">Product Id</span>
                <input
                  type="text"
                  id="productId"
                  name="productId"
                  value={entry.product_id}
                  onChange={handleLineItemUpdate(index, 'product_id')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                  required
                />
              </div>
              <div key="sku">
                <span htmlFor="sku" className="block text-sm font-medium text-gray-700">SKU</span>
                <input
                  type="text"
                  id="sku"
                  name="sku"
                  value={entry.sku}
                  onChange={handleLineItemUpdate(index, 'sku')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                />
              </div>
              <div key="quantity">
                <span htmlFor="quantity" className="block text-sm font-medium text-gray-700">Quantity</span>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={entry.quantity}
                  onChange={handleLineItemUpdate(index, 'quantity')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                  required
                />
              </div>
              <div key="unitPrice">
                <span htmlFor="unitPrice" className="block text-sm font-medium text-gray-700">Unit Price</span>
                <input
                  type="text"
                  id="unitPrice"
                  name="unitPrice"
                  value={entry.unit_price}
                  onChange={handleLineItemUpdate(index, 'unit_price')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                />
              </div>
              <div key="discountAmount">
                <span htmlFor="discountAmount" className="block text-sm font-medium text-gray-700">Discount Amount</span>
                <input
                  type="text"
                  id="discountAmount"
                  name="discountAmount"
                  value={entry.discount_amount}
                  onChange={handleLineItemUpdate(index, 'discount_amount')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                />
              </div>
            </>
          ))}
          <div className="col-span-3 gap-2 pt-2">
            <button type="button" onClick={() => addLineItem()} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-1 px-4 rounded-lg mr-2 mt-2">Add Line Item</button>
          </div>
        </div>
        <div className="flex flex-grwo justify-end pt-4">
          <button
            type="button"
            onClick={() => onClose()}
            className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="self-end bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            disabled={loading}
          >
            {existingOrder ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default CreateUpdateOrderModal;
