import React, { useEffect, useState } from 'react';
import {
  createNewResource, formatDateForApi, getNewReturnDefaults, updateReturn,
} from './SandboxApi';

function CreateUpdateReturnModal({
  isOpen, onClose, accessToken, apiKey, handleCreateNewResource, existingReturn,
  handleCreateUpdateError,
}) {
  const [returnData, setReturnData] = useState(getNewReturnDefaults());
  const [loading, setLoading] = useState(false);
  const defaultLineItem = {
    sku: 'sku',
    quantity: 0,
  };

  const handleClose = () => {
    setReturnData(getNewReturnDefaults());
    setLoading(false);
    onClose();
  };

  const handleCreateUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    if (existingReturn) {
      updateReturn(accessToken, apiKey, existingReturn.id, returnData)
        .then((response) => {
          handleCreateNewResource(response);
          handleClose();
        }).catch((error) => {
          handleCreateUpdateError(error);
          setLoading(false);
        });
    } else {
      createNewResource(accessToken, apiKey, 'returns', returnData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    }
  };

  const addLineItem = () => {
    setReturnData({ ...returnData, line_items: [...returnData.line_items, defaultLineItem] });
  };

  const handleLineItemUpdate = (lineItemNum, updatedField) => (e) => {
    const newLineItems = [...returnData.line_items];
    if (updatedField === 'returned_date') {
      newLineItems[lineItemNum][updatedField] = formatDateForApi(e.target.value);
    } else {
      newLineItems[lineItemNum][updatedField] = e.target.value;
    }
    setReturnData({ ...returnData, line_items: newLineItems });
  };

  useEffect(() => {
    if (existingReturn) {
      setReturnData(existingReturn);
    } else {
      setReturnData(getNewReturnDefaults());
    }
  }, [existingReturn]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => onClose()} tabIndex={-1} aria-hidden="true" />
      <form className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-fit" onSubmit={handleCreateUpdate}>
        <h2 className="text-2xl font-semibold pt-1 pb-0">
          {existingReturn ? 'Update' : 'Create New'}
          {' '}
          Return
        </h2>
        <div className="grid grid-cols-3 gap-2" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div key="orderId">
            <span htmlFor="orderId" className="block text-sm font-medium text-gray-700">Order Id</span>
            <input
              type="text"
              id="orderId"
              name="orderId"
              value={returnData.order_id}
              onChange={(e) => {
                setReturnData({ ...returnData, order_id: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="warehouseId">
            <span htmlFor="warehouseId" className="block text-sm font-medium text-gray-700">Warehouse Id</span>
            <input
              type="text"
              id="warehouseId"
              name="warehouseId"
              value={returnData.warehouse_id}
              onChange={(e) => {
                setReturnData({ ...returnData, warehouse_id: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <hr className="col-span-3" style={{ borderTop: '1px solid #bbb', marginTop: '12px' }} />
          <span className="col-span-3 block text-sm font-medium text-gray-700">Line Items</span>
          {returnData.line_items.map((entry, index) => (
            <>
              <hr className="col-span-3" style={{ borderTop: '1px dashed #bbb', marginTop: '12px' }} />
              <span className="col-span-3 block text-sm font-medium text-gray-700">
                Line Item #
                {index + 1}
              </span>
              <div key="sku">
                <span htmlFor="sku" className="block text-sm font-medium text-gray-700">SKU</span>
                <input
                  type="text"
                  id="sku"
                  name="sku"
                  value={entry.sku}
                  onChange={handleLineItemUpdate(index, 'sku')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                  required
                />
              </div>
              <div key="quantity">
                <span htmlFor="quantity" className="block text-sm font-medium text-gray-700">Quantity</span>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={entry.quantity}
                  onChange={handleLineItemUpdate(index, 'quantity')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                  required
                />
              </div>
            </>
          ))}
          <div className="col-span-3 gap-2 pt-2">
            <button type="button" onClick={() => addLineItem()} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-1 px-4 rounded-lg mr-2 mt-2">Add Line Item</button>
          </div>
        </div>
        <div className="flex flex-grow justify-end pt-4">
          <button
            type="button"
            onClick={() => onClose()}
            className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="self-end bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            disabled={loading}
          >
            {existingReturn ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default CreateUpdateReturnModal;
