import React, { useState } from 'react';

function HorizontalScrollText({ content, secret = false, onDelete = undefined }) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500); // Reset the copied state after 1.5 seconds
  };

  return (
    <div className="modal w-full">
      <div className="modal-content overflow-x-auto whitespace-nowrap relative">
        <div className="flex items-center gap-1">
          <div className="flex-grow px-2">{secret ? '**********'.concat(content.slice(-4)) : content}</div>
          <button
            type="button"
            onClick={copyToClipboard}
            className="px-4 py-2 bg-trackstarAllDark text-white rounded-lg"
            // there's a few px overflorw to the right on some screens. do -2 to be safe
            style={{ position: 'sticky', right: -2, top: 0 }}
          >
            {isCopied ? 'Copied!' : 'Copy'}
          </button>
          {onDelete && (
            <button
              type="button"
              onClick={() => onDelete(content)}
              className="px-4 py-2 bg-red-500 text-white rounded-lg"
              // there's a few px overflorw to the right on some screens. do -2 to be safe
              style={{ position: 'sticky', right: -2, top: 0 }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default HorizontalScrollText;
