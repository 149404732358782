import React from 'react';

export default function CustomDetailPanelToggle(props) {
  const { value: isExpanded } = props;
  const imgSrc = isExpanded ? 'chevron-down.png' : 'chevron-right.png';

  return (
    <img src={imgSrc} alt="toggle" className="h-5" />
  );
}
