import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

function GenerateSandboxModal({
  isOpen, onClose, onConfirm, loading = false, completed = false,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-gray-800 opacity-50"
        onClick={onClose}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            onClose();
          }
        }}
        tabIndex={-1}
        aria-hidden="true"
      />

      <div className="bg-white rounded-lg p-6 w-96 relative z-10">
        <h2 className="text-lg font-semibold mb-4">Regenerate Sandbox</h2>
        {!completed && (loading ? <ClipLoader /> : (
          <>
            <p className="mb-4">This will reset the sandbox environment to its default state.</p>
            <p className="mb-4">Are you sure you want to proceed?</p>
            <div className="flex justify-end">
              <button
                type="submit"
                onClick={onClose}
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={onConfirm}
                className="bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
              >
                Confirm
              </button>
            </div>
          </>
        ))}
        {
          completed && (
            <div className="mt-4 flex gap-3 flex-col">
              <p className="mb-2">Sandbox Regenerated Successfully</p>
              <button type="submit" onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg w-1/4 ml-auto">
                Close
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default GenerateSandboxModal;
