import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';

function Settings() {
  const [pathName, setPathName] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      const temp = location.pathname.split('/').pop();
      setPathName(temp);
    }
  }, [location]);

  return (
    <div className="flex flex-col gap-6 p-5 w-10/12 h-screen overflow-y-auto">
      <h1 className="text-3xl font-semibold leading-9 mb-4">
        Settings
      </h1>
      <div className="flex">
        <div className="flex flex-col gap-6 w-1/6">
          <Link to="users" className={`text-md font-medium ${pathName === 'users' ? 'text-black' : 'text-gray-600'}`}>Users</Link>
          <Link to="api-keys" className={`text-md font-medium ${pathName === 'api-keys' ? 'text-black' : 'text-gray-600'}`}>API Keys</Link>
          <Link to="sync-settings" className={`text-md font-medium ${pathName === 'sync-settings' ? 'text-black' : 'text-gray-600'}`}>Sync Settings</Link>
          <Link to="branding" className={`text-md font-medium ${pathName === 'branding' ? 'text-black' : 'text-gray-600'}`}>Branding</Link>
          <Link to="magic-links" className={`text-md font-medium ${pathName === 'magic-links' ? 'text-black' : 'text-gray-600'}`}>Magic Links</Link>
        </div>
        <div className="flex flex-col w-5/6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Settings;
