import React, { useEffect, useState } from 'react';
import {
  createNewResource,
  formatDateForApi,
  getNewInboundDefaults,
  updateInboundShipment,
  formatDateForInput,
} from './SandboxApi';

function CreateUpdateInboundShipmentModal({
  isOpen, onClose, accessToken, apiKey, handleCreateNewResource, existingShipment,
  handleCreateUpdateError,
}) {
  const [inboundData, setInboundData] = useState(getNewInboundDefaults());
  const [loading, setLoading] = useState(false);
  const defaultLineItem = {
    expected_quantity: 0,
    sku: 'sku',
  };

  const handleClose = () => {
    setInboundData(getNewInboundDefaults());
    setLoading(false);
    onClose();
  };

  const handleCreateUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    if (existingShipment) {
      updateInboundShipment(accessToken, apiKey, existingShipment.id, inboundData)
        .then((response) => {
          handleCreateNewResource(response);
          handleClose();
        }).catch((error) => {
          handleCreateUpdateError(error);
          setLoading(false);
        });
    } else {
      createNewResource(accessToken, apiKey, 'inbound-shipments', inboundData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    }
  };

  const addLineItem = () => {
    setInboundData({ ...inboundData, line_items: [...inboundData.line_items, defaultLineItem] });
  };

  const handleLineItemUpdate = (lineItemNum, updatedField) => (e) => {
    const newLineItems = [...inboundData.line_items];
    newLineItems[lineItemNum][updatedField] = e.target.value;
    setInboundData({ ...inboundData, line_items: newLineItems });
  };

  useEffect(() => {
    if (existingShipment) {
      setInboundData(existingShipment);
    } else {
      setInboundData(getNewInboundDefaults());
    }
  }, [existingShipment]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => onClose()} tabIndex={-1} aria-hidden="true" />
      <form className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-fit" onSubmit={handleCreateUpdate}>
        <h2 className="text-2xl font-semibold pt-1 pb-0">
          {existingShipment ? 'Update' : 'Create New'}
          {' '}
          Inbound Shipment
        </h2>
        <div className="grid grid-cols-3 gap-2" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div key="purchaseOrderNumber">
            <span htmlFor="purchaseOrderNumber" className="block text-sm font-medium text-gray-700">Purchase Order Number</span>
            <input
              type="text"
              id="purchaseOrderNumber"
              name="purchaseOrderNumber"
              value={inboundData.purchase_order_number}
              onChange={(e) => {
                setInboundData({ ...inboundData, purchase_order_number: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="expectedArrivalDate">
            <span htmlFor="expectedArrivalDate" className="block text-sm font-medium text-gray-700">Expected Arrival Date</span>
            <input
              type="datetime-local"
              id="expectedArrivalDate"
              name="expectedArrivalDate"
              value={formatDateForInput(inboundData.expected_arrival_date)}
              onChange={(e) => {
                setInboundData({
                  ...inboundData, expected_arrival_date: formatDateForApi(e.target.value),
                });
              }}
              className="border border-gray-300 rounded-md py-2 px-1"
              size="small"
            />
          </div>
          <div key="supplier">
            <span htmlFor="supplier" className="block text-sm font-medium text-gray-700">Supplier</span>
            <input
              type="text"
              id="supplier"
              name="supplier"
              value={inboundData.supplier}
              onChange={(e) => setInboundData({ ...inboundData, supplier: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="warehouseId">
            <span htmlFor="warehouseId" className="block text-sm font-medium text-gray-700">Warehouse Id</span>
            <input
              type="text"
              id="warehouseId"
              name="warehouseId"
              value={inboundData.warehouse_id}
              onChange={(e) => setInboundData({ ...inboundData, warehouse_id: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="trackingNumber">
            <span htmlFor="trackingNumber" className="block text-sm font-medium text-gray-700">Tracking Number</span>
            <input
              type="text"
              id="trackingNumber"
              name="trackingNumber"
              value={inboundData.tracking_number}
              onChange={(e) => {
                setInboundData({ ...inboundData, tracking_number: e.target.value });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <hr className="col-span-3" style={{ borderTop: '1px solid #bbb', marginTop: '12px' }} />
          <span className="col-span-3 block text-sm font-medium text-gray-700">Line Items</span>
          {inboundData.line_items.map((entry, index) => (
            <>
              <hr className="col-span-3" style={{ borderTop: '1px dashed #bbb', marginTop: '12px' }} />
              <span className="col-span-3 block text-sm font-medium text-gray-700">
                Line Item #
                {index + 1}
              </span>
              <div key="sku">
                <span htmlFor="sku" className="block text-sm font-medium text-gray-700">SKU</span>
                <input
                  type="text"
                  id="sku"
                  name="sku"
                  value={entry.sku}
                  onChange={handleLineItemUpdate(index, 'sku')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                />
              </div>
              <div key="expectedQuantity">
                <span htmlFor="expectedQuantity" className="block text-sm font-medium text-gray-700">Expected Quantity</span>
                <input
                  type="number"
                  id="expectedQuantity"
                  name="expectedQuantity"
                  value={entry.expected_quantity}
                  onChange={handleLineItemUpdate(index, 'expected_quantity')}
                  className="border border-gray-300 rounded-md p-2"
                  size="small"
                  required
                />
              </div>
            </>
          ))}
          <div className="col-span-3 gap-2 pt-2">
            <button type="button" onClick={() => addLineItem()} className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-1 px-4 rounded-lg mr-2 mt-2">Add Line Item</button>
          </div>
        </div>
        <div className="flex flex-grow justify-end pt-4">
          <button
            type="button"
            onClick={() => onClose()}
            className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="self-end bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            disabled={loading}
          >
            {existingShipment ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default CreateUpdateInboundShipmentModal;
