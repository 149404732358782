import React, { useEffect, useState } from 'react';
import {
  getNewInventoryDefaults, formatDateForApi, formatDateForInput,
  createNewResource, getResources, updateInventoryItem,
} from './SandboxApi';

function CreateUpdateInventoryModal({
  isOpen, onClose, accessToken, apiKey, handleCreateNewResource, existingInventory,
  handleCreateUpdateError,
}) {
  const [inventoryData, setInventoryData] = useState(getNewInventoryDefaults());
  const [loading, setLoading] = useState(false);
  const [productSkus, setProductSkus] = useState([]);

  const handleClose = () => {
    setInventoryData(getNewInventoryDefaults());
    setLoading(false);
    onClose();
  };

  const handleCreateUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    if (existingInventory) {
      updateInventoryItem(accessToken, apiKey, existingInventory.id, inventoryData)
        .then((response) => {
          handleCreateNewResource(response);
          handleClose();
        });
    } else {
      createNewResource(accessToken, apiKey, 'inventory', inventoryData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (existingInventory) {
      setInventoryData(existingInventory);
    } else {
      setInventoryData(getNewInventoryDefaults());
    }
    getResources(accessToken, apiKey, 'products').then((data) => {
      const skus = data.map((product) => product.sku);
      setProductSkus(skus);
      if (!existingInventory) {
        setInventoryData({ ...inventoryData, sku: skus[0] });
      }
    });
  }, [existingInventory]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => handleClose()} tabIndex={-1} aria-hidden="true" />
      <form className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-3/4" onSubmit={handleCreateUpdate}>
        <h2 className="text-2xl font-semibold pt-1 pb-0">
          {existingInventory ? 'Update' : 'Create New'}
          {' '}
          Inventory Item
        </h2>
        <div className="grid grid-cols-3 gap-2" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div key="name">
            <span htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </span>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={inventoryData.name}
              onChange={(e) => setInventoryData({ ...inventoryData, name: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="sku">
            <span htmlFor="sku" className="block text-sm font-medium text-gray-700">SKU</span>
            <select
              id="sku"
              name="sku"
              value={inventoryData.sku}
              onChange={(e) => setInventoryData({ ...inventoryData, sku: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
            >
              {productSkus.map((sku) => (
                <option value={sku}>{sku}</option>
              ))}
            </select>
          </div>
          <div key="active">
            <span htmlFor="active" className="block text-sm font-medium text-gray-700">
              Active
            </span>
            <input
              type="checkbox"
              id="active"
              name="active"
              value={inventoryData.active}
              checked={inventoryData.active}
              onChange={(e) => setInventoryData({ ...inventoryData, active: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="awaiting">
            <span htmlFor="awaiting" className="block text-sm font-medium text-gray-700">
              Awaiting
            </span>
            <input
              type="number"
              id="awaiting"
              name="awaiting"
              value={inventoryData.awaiting}
              checked={inventoryData.awaiting}
              onChange={(e) => setInventoryData({ ...inventoryData, awaiting: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="onhand">
            <span htmlFor="onhand" className="block text-sm font-medium text-gray-700">
              Onhand
            </span>
            <input
              type="number"
              id="onhand"
              name="onhand"
              value={inventoryData.onhand}
              checked={inventoryData.onhand}
              onChange={(e) => setInventoryData({ ...inventoryData, onhand: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="committed">
            <span htmlFor="committed" className="block text-sm font-medium text-gray-700">
              Committed
            </span>
            <input
              type="number"
              id="committed"
              name="committed"
              value={inventoryData.committed}
              checked={inventoryData.committed}
              onChange={(e) => setInventoryData({ ...inventoryData, committed: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="unfulfillable">
            <span htmlFor="unfulfillable" className="block text-sm font-medium text-gray-700">
              Unfulfillable
            </span>
            <input
              type="number"
              id="unfulfillable"
              name="unfulfillable"
              value={inventoryData.unfulfillable}
              checked={inventoryData.unfulfillable}
              onChange={(e) => setInventoryData({
                ...inventoryData, unfulfillable: e.target.value,
              })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="fulfillable">
            <span htmlFor="fulfillable" className="block text-sm font-medium text-gray-700">
              Fulfillable
            </span>
            <input
              type="number"
              id="fulfillable"
              name="fulfillable"
              value={inventoryData.fulfillable}
              checked={inventoryData.fulfillable}
              onChange={(e) => setInventoryData({ ...inventoryData, fulfillable: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="unsellable">
            <span htmlFor="unsellable" className="block text-sm font-medium text-gray-700">
              Unsellable
            </span>
            <input
              type="number"
              id="unsellable"
              name="unsellable"
              value={inventoryData.unsellable}
              checked={inventoryData.unsellable}
              onChange={(e) => setInventoryData({ ...inventoryData, unsellable: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="sellable">
            <span htmlFor="sellable" className="block text-sm font-medium text-gray-700">
              Sellable
            </span>
            <input
              type="number"
              id="sellable"
              name="sellable"
              value={inventoryData.sellable}
              checked={inventoryData.sellable}
              onChange={(e) => setInventoryData({ ...inventoryData, sellable: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="warehouse_customer_id">
            <span htmlFor="warehouse_customer_id" className="block text-sm font-medium text-gray-700">
              Warehouse Customer ID
            </span>
            <input
              type="text"
              id="warehouse_customer_id"
              name="warehouse_customer_id"
              value={inventoryData.warehouse_customer_id}
              onChange={(e) => setInventoryData(
                { ...inventoryData, warehouse_customer_id: e.target.value },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="created_date">
            <span htmlFor="created_date" className="block text-sm font-medium text-gray-700">
              Created Date
            </span>
            <input
              type="datetime-local"
              id="created_date"
              name="created_date"
              required
              value={formatDateForInput(inventoryData.created_date)}
              onChange={(e) => setInventoryData(
                { ...inventoryData, created_date: formatDateForApi(e.target.value) },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="updated_date">
            <span htmlFor="updated_date" className="block text-sm font-medium text-gray-700">
              Updated Date
            </span>
            <input
              type="datetime-local"
              id="updated_date"
              name="updated_date"
              required
              value={formatDateForInput(inventoryData.updated_date)}
              onChange={(e) => setInventoryData(
                { ...inventoryData, updated_date: formatDateForApi(e.target.value) },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <hr className="col-span-3" style={{ borderTop: '1px solid #bbb', marginTop: '12px' }} />
          <span className="col-span-3 block text-sm font-medium text-gray-700">Measurements</span>
          <div key="length">
            <span htmlFor="length" className="block text-sm font-medium text-gray-700">
              Length
            </span>
            <input
              type="number"
              id="length"
              name="length"
              value={inventoryData.measurements.length}
              checked={inventoryData.measurements.length}
              onChange={(e) => setInventoryData(
                {
                  ...inventoryData,
                  measurements:
                  { ...inventoryData.measurements, length: e.target.value },
                },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="width">
            <span htmlFor="width" className="block text-sm font-medium text-gray-700">
              Width
            </span>
            <input
              type="number"
              id="width"
              name="width"
              value={inventoryData.measurements.width}
              checked={inventoryData.measurements.width}
              onChange={(e) => setInventoryData(
                {
                  ...inventoryData,
                  measurements:
                   { ...inventoryData.measurements, width: e.target.value },
                },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="height">
            <span htmlFor="height" className="block text-sm font-medium text-gray-700">
              Height
            </span>
            <input
              type="number"
              id="height"
              name="height"
              value={inventoryData.measurements.height}
              checked={inventoryData.measurements.height}
              onChange={(e) => setInventoryData(
                {
                  ...inventoryData,
                  measurements:
                   { ...inventoryData.measurements, height: e.target.value },
                },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="unit">
            <span htmlFor="unit" className="block text-sm font-medium text-gray-700">
              Unit
            </span>
            <input
              type="text"
              id="unit"
              name="unit"
              value={inventoryData.measurements.unit}
              checked={inventoryData.measurements.unit}
              onChange={(e) => setInventoryData(
                {
                  ...inventoryData,
                  measurements:
                  { ...inventoryData.measurements, unit: e.target.value },
                },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="weight">
            <span htmlFor="weight" className="block text-sm font-medium text-gray-700">
              Weight
            </span>
            <input
              type="number"
              id="weight"
              name="weight"
              value={inventoryData.measurements.weight}
              checked={inventoryData.measurements.weight}
              onChange={(e) => setInventoryData(
                {
                  ...inventoryData,
                  measurements:
                   { ...inventoryData.measurements, weight: e.target.value },
                },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="weight_unit">
            <span htmlFor="weight_unit" className="block text-sm font-medium text-gray-700">
              Weight Unit
            </span>
            <input
              type="text"
              id="weight_unit"
              name="weight_unit"
              value={inventoryData.measurements.weight_unit}
              checked={inventoryData.measurements.weight_unit}
              onChange={(e) => setInventoryData(
                {
                  ...inventoryData,
                  measurements:
                   { ...inventoryData.measurements, weight_unit: e.target.value },
                },
              )}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
        </div>
        <div className="flex flex-grow justify-end pt-4">
          <button
            type="button"
            onClick={() => onClose()}
            className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="self-end bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            disabled={loading}
          >
            {existingInventory ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default CreateUpdateInventoryModal;
