import React, { useState } from 'react';

function InviteUserModal({ isOpen, onClose, sendInvite }) {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('admin');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendInvite(email, role);
    // Close the modal
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-gray-800 opacity-50"
        onClick={onClose}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            onClose();
          }
        }}
        tabIndex={-1}
        aria-hidden="true"
      />
      <div className="bg-white rounded-lg p-6 w-96 relative z-10">
        <h2 className="text-lg font-semibold mb-4">Invite User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            {/* eslint-disable */}
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {/* eslint-enable */}
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="mt-1 px-3 py-2 w-full border rounded-lg focus:ring focus:ring-blue-200"
              required
            />
          </div>
          <div className="mb-4">
            {/* eslint-disable */}  
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
              {/* eslint-enable */}
              Role
            </label>
            <select
              id="role"
              name="role"
              value={role}
              onChange={handleRoleChange}
              className="mt-1 px-3 py-2 w-full border rounded-lg focus:ring focus:ring-blue-200"
            >
              <option value="admin">Admin</option>
              <option value="basic_member">Basic Member</option>
            </select>
          </div>
          <div className="text-right">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InviteUserModal;
