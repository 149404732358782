import React, { useState, useRef, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Sketch } from '@uiw/react-color';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReplayIcon from '@mui/icons-material/Replay';
import { useFetch, useSendImage } from './api';

function Branding() {
  const [firstCrawlLookbackDays, setFirstCrawlLookbackDays] = useState(30);
  const [loadingOrgSettings, setLoadingOrgSettings] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [customLogo, setCustomLogo] = useState(null);
  const [brandColor, setBrandColor] = useState(null);
  const [savingSettings, setSavingSettings] = useState(false);
  const [savingLogo, setSavingLogo] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(true);

  const trackstar = useFetch();
  const sendImage = useSendImage();

  const fileInput = useRef(null);

  const defaultColor = '#3b82f6';

  const getOrgSettings = () => {
    setLoadingOrgSettings(true);
    trackstar('internal/org').then((response) => {
      const { data } = response;
      setFirstCrawlLookbackDays(data.first_crawl_lookback_days);
      setBrandColor(data.brand_color || defaultColor);
      setLoadingOrgSettings(false);
    });
  };

  const saveSettings = () => {
    setSavingSettings(true);
    trackstar(
      'internal/org-settings',
      'POST',
      {
        first_crawl_lookback_days: firstCrawlLookbackDays,
        brand_color: brandColor,
      },
    ).then(() => setSavingSettings(false));
  };

  const getCustomLogo = () => {
    setLoadingLogo(true);
    trackstar('internal/brand-logo', 'GET', null, null, 'blob')
      .then((response) => {
        let logoUrl = null;
        if (response.size) {
          logoUrl = URL.createObjectURL(new Blob([response], { type: 'image/png' }));
        }
        setCustomLogo(logoUrl);
        setLoadingLogo(false);
      });
  };

  const uploadCustomLogo = (logo) => {
    setSavingLogo(true);
    const formData = new FormData();
    formData.append('custom_logo', logo);
    sendImage(
      'internal/brand-logo',
      formData,
    ).then(() => setSavingLogo(false));
  };

  const deleteCustomLogo = () => {
    setSavingLogo(true);
    trackstar('internal/brand-logo', 'DELETE').then(() => {
      setCustomLogo(null);
      setSavingLogo(false);
    });
  };

  const handleLogoChange = (event) => {
    if (event.target.files[0]) {
      setCustomLogo(URL.createObjectURL(event.target.files[0]));
      setUpdateDisabled(false);
    }
  };

  const logoStyle = {
    width: '70px',
    height: '70px',
    borderRadius: 5,
  };

  useEffect(() => {
    setLoadingOrgSettings(true);
    setLoadingLogo(true);
    getOrgSettings();
    getCustomLogo();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Branding</h1>
      </div>
      <div className="border p-4 rounded-md shadow-sm flex flex-col gap-6">
        <div className="flex flex-col bg-white gap-2">
          <span className="text-sm font-semibold">Custom Logo</span>
          <p className="text-sm text-gray-500 mt-1">
            This icon will be displayed in the Trackstar Link Modal.
          </p>
          {(loadingLogo || loadingOrgSettings) ? <div className="flex"><ClipLoader color="#C9B0F0" size={20} /></div> : (
            <div className="flex items-center justify-left gap-2">
              {customLogo ? (
                <img src={customLogo} alt="Custom Logo" style={logoStyle} className="mx-2" />
              ) : (
                <img
                  src="/ts_logo_2024.png"
                  alt="Default Logo"
                  style={logoStyle}
                  className="mx-2"
                />
              )}
              <button
                type="button"
                style={{ padding: '5px 10px', borderRadius: 5 }}
                className="border rounded-md flex items-center justify-center border-black"
                onClick={() => fileInput.current.click()}
                title="Upload Logo"
                htmlFor="logoInput"
              >
                <input
                  hidden
                  type="file"
                  id="logoInput"
                  name="logoInput"
                  accept="image/*"
                  onChange={handleLogoChange}
                  ref={fileInput}
                />
                <span className="text-gray-500 flex-col">
                  <FileUploadIcon style={{ fontSize: 20, paddingRight: 5 }} />
                  Upload
                </span>
              </button>
              <button
                type="button"
                style={{ padding: '5px 10px', borderRadius: 5 }}
                className={`border rounded-md flex items-center justify-center ${customLogo ? 'border-black' : 'border-gray-300'}`}
                onClick={() => {
                  setCustomLogo(null);
                  if (fileInput.current.files.length > 0) {
                  // reset file input so it registers onChange
                    fileInput.current.value = '';
                  }
                  setUpdateDisabled(false);
                }}
                disabled={!customLogo}
              >
                <span className="text-gray-500 flex-col">
                  <ReplayIcon style={{ fontSize: 20, paddingRight: 5 }} />
                  Revert to Default
                </span>
              </button>
            </div>
          )}
        </div>
        <div className="border-t border-gray-300" />
        <div className="flex flex-col justify-between bg-white gap-2">
          <span className="text-sm font-semibold">Custom Color</span>
          <p className="text-sm text-gray-500 mt-1">
            Set to your brand color to customize the Trackstar Link Modal.
          </p>
          {(loadingOrgSettings) ? <div className="flex"><ClipLoader color="#C9B0F0" size={20} /></div> : (
            <div className="flex flex-col justify-between bg-white gap-6 pt-2 mx-2">
              <div className="flex gap-6">
                <div className="flex items-center justify-left gap-2">
                  <div
                    className="rounded-full"
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: brandColor || defaultColor,
                    }}
                  />
                  <div
                    type="text"
                    style={{ padding: '5px 10px', borderRadius: 5 }}
                    className="text-gray-500 border-gray-300 border rounded-md flex items-center justify-center font-mono"
                    disabled
                  >
                    {brandColor || defaultColor}
                  </div>
                </div>
                <div className="flex items-center justify-left gap-2">
                  <div
                    className="rounded-full"
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: defaultColor,
                    }}
                  />
                  <button
                    type="button"
                    style={{ padding: '5px 10px', borderRadius: 5 }}
                    className={`text-gray-500 border rounded-md flex items-center justify-center ${brandColor === null ? 'border-gray-300' : 'border-black'}`}
                    onClick={() => {
                      setBrandColor(null);
                      setUpdateDisabled(false);
                    }}
                    disabled={brandColor === null}
                  >
                    <span className="text-gray-500 flex-col">
                      <ReplayIcon style={{ fontSize: 20, paddingRight: 5 }} />
                      Revert to Default
                    </span>
                  </button>
                </div>
              </div>
              <Sketch
                prefixCls="color-picker"
                disableAlpha
                color={brandColor || defaultColor}
                onChange={(color) => {
                  setBrandColor(color.hex);
                  setUpdateDisabled(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        {savingLogo || savingSettings
          ? <ClipLoader color="#C9B0F0" size={20} />
          : (
            <button
              type="button"
              className={`px-4 py-2 h-10 w-20 rounded ${(updateDisabled) ? 'bg-gray-300 cursor-not-allowed text-gray-500' : 'bg-blue-500 text-white'}`}
              onClick={() => {
                saveSettings();
                if (fileInput.current.files.length > 0) {
                  uploadCustomLogo(fileInput.current.files[0]);
                } else if (!customLogo) {
                  deleteCustomLogo();
                }
                setUpdateDisabled(true);
              }}
              disabled={updateDisabled}
            >
              Update
            </button>
          )}
      </div>
    </div>
  );
}

export default Branding;
