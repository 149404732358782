import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

function SyncConnectionModal({
  isOpen, onClose, onSync, isLoading, row,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-gray-800 opacity-50"
        onClick={onClose}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            onClose();
          }
        }}
        tabIndex={-1}
        aria-hidden="true"
      />

      <div className="bg-white rounded-lg p-6 w-96 relative z-10">
        <h2 className="text-lg font-semibold mb-4">Sync Connection</h2>
        <p className="mb-4">{'This action will sync the connection\'s WMS data from the time shown in Latest Sync to now. To proceed, click Confirm.'}</p>
        {isLoading ? <ClipLoader /> : (
          <div className="flex justify-end">
            <button
              type="submit"
              onClick={() => onClose(true)}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => onSync(row)}
              className="bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SyncConnectionModal;
