import React, { useState, useEffect } from 'react';
import './App.css';
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  useOrganization,
  useOrganizationList,
} from '@clerk/clerk-react';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import { useFetch } from './api';
import Connections from './Connections';
import Webhooks from './Webhooks';
import Navbar from './Navbar';
import Homepage from './Homepage';
import Settings from './Settings';
import ApiLogPage from './ApiLogPage';
import Users from './Users';
import ApiKeys from './ApiKeys';
import SyncSettings from './SyncSettings';
import Branding from './Branding';
import MagicLinks from './MagicLinks';
import Sandbox from './Sandbox';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  return (
    <BrowserRouter>
      <ClerkProvider
        publishableKey={clerkPubKey}
      >
        <ClerkProviderWithRoutes />
      </ClerkProvider>
    </BrowserRouter>
  );
}

function ClerkProviderWithRoutes() {
  const [numIssues, setNumIssues] = useState(0);
  const { organizationList, setActive } = useOrganizationList();
  const { organization } = useOrganization();
  const [connectionData, setConnectionData] = useState(null);
  const [loadingConnectionData, setLoadingConnectionData] = useState(false);
  const trackstar = useFetch();

  const fetchConnectionData = () => {
    setLoadingConnectionData(true);
    trackstar('internal/connections').then((response) => {
      const { data } = response;
      setConnectionData(data);
      setLoadingConnectionData(false);
      let connectionIssues = 0;
      data.forEach((connection) => {
        connectionIssues += connection.errors.length;
      });
      setNumIssues(connectionIssues);
    });
  };

  // TODO wait for org list to load
  useEffect(() => {
    if (!organization && organizationList?.length > 0) {
      const orgId = organizationList[0].organization.id;
      setActive({ organization: orgId });
    }
  }, [organizationList, setActive, organization]);

  useEffect(() => {
    if (organization) {
      fetchConnectionData();
    }
  }, [organization]);

  // Undefined means we are still loading the organization
  // Null means the organization does not exist
  if (organization === undefined) {
    return <ClipLoader />;
  }
  return (
    <>
      <SignedIn>
        <div className="flex gap-5">
          <Navbar numIssues={numIssues} />
          {organization === null
            ? (
              <div className="flex justify-center items-center h-screen w-full">
                <div className="flex flex-col justify-center items-center border p-10">
                  <h1 className="text-4xl font-semibold leading-9">Welcome to Trackstar</h1>
                  <p className="text-lg font-medium py-5">Your organization is not set up, please reach out to support@trackstarhq.com for access</p>
                </div>
              </div>
            )
            : (
              <Routes>
                <Route path="/" element={<Homepage connectionData={connectionData} connectionErrors={numIssues} loadingConnectionData={loadingConnectionData} />} />
                <Route path="/connections" element={<Connections fetchConnectionData={fetchConnectionData} connectionIssues={numIssues} connectionData={connectionData} loadingConnectionData={loadingConnectionData} />} />
                <Route path="/webhooks" element={<Webhooks />} />
                <Route path="/settings" element={<Settings />}>
                  <Route index element={<Navigate replace to="users" />} />
                  <Route path="users" element={<Users />} />
                  <Route path="api-keys" element={<ApiKeys />} />
                  <Route path="sync-settings" element={<SyncSettings />} />
                  <Route path="branding" element={<Branding />} />
                  <Route path="magic-links" element={<MagicLinks />} />
                </Route>
                <Route path="/api-log" element={<ApiLogPage connectionData={connectionData} loadingConnectionData={loadingConnectionData} />} />
                <Route path="/sandbox" element={<Sandbox connectionData={connectionData} />} />
              </Routes>
            )}
        </div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}

export default App;
