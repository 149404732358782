/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  DataGridPro, GridActionsCellItem, GridToolbar,
} from '@mui/x-data-grid-pro';
import { useOrganization } from '@clerk/clerk-react';
import { useFetch } from './api';
import { getResources, fulfillOrder, cancelOrder } from './SandboxApi';
import CreateUpdateOrderModal from './CreateUpdateOrderModal';
import CreateUpdateInboundShipmentModal from './CreateUpdateInboundShipmentModal';
import CreateUpdateReturnModal from './CreateUpdateReturnModal';
import CreateNewProductModal from './CreateNewProductModal';
import GenerateSandboxModal from './GenerateSandboxModal';
import CreateUpdateInventoryModal from './CreateUpdateInventoryModal';
// import CreateUpdateResource from './CreateUpdateResource';

function NoRowsOverLay() {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="flex flex-col items-center">
        <div className="text-2xl font-semibold">No Sandbox Generated</div>
        <div className="text-lg font-normal">Press Regenerate Sandbox Data to get started</div>
      </div>
    </div>
  );
}

function Sandbox(props) {
  const { organization } = useOrganization();
  const trackstar = useFetch();
  const [accessToken, setAccessToken] = useState();
  const [apiKeys, setApiKeys] = useState([]);
  const [resourcesLoading, setResourcesLoading] = useState(false);
  const [selectedResource, setSelectedResource] = useState('orders');
  const [createNewResourceModalOpen, setCreateNewResourceModalOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [resourceToUpdate, setResourceToUpdate] = useState(null);
  const [createUpdateError, setCreateUpdateError] = useState(false);
  const [isGenerateSandBoxModalOpen, setIsGenerateSandBoxModalOpen] = useState(false);
  const [sandboxRegenerated, setSandboxRegenerated] = useState(false);

  const { connectionData } = props;
  const orderColumns = [
    {
      field: 'id', headerName: 'ID', width: 150, hideable: false,
    },
    {
      field: 'order_number', headerName: 'Order Number', width: 150, hideable: false,
    },
    { field: 'reference_id', headerName: 'Reference Id', width: 150 },
    { field: 'created_date', headerName: 'Order Date', width: 150 },
    { field: 'updated_date', headerName: 'Updated Date', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'raw_status', headerName: 'Raw Status', width: 150 },
    { field: 'channel', headerName: 'Channel', width: 150 },
    { field: 'trading_partner', headerName: 'Trading Partner', width: 150 },
    { field: 'shipping_method', headerName: 'Shipping Method', width: 150 },
    { field: 'invoice_currency_code', headerName: 'Invoice Currency Code', width: 150 },
    {
      field: 'total_price',
      headerName: 'Total Price',
      width: 150,
      renderCell: (params) => <span>{`$${params.value.toFixed(2)}`}</span>,
    },
    {
      field: 'total_tax',
      headerName: 'Total Tax',
      width: 150,
      renderCell: (params) => <span>{`$${params.value.toFixed(2)}`}</span>,
    },
    {
      field: 'total_discount',
      headerName: 'Total Discount',
      width: 150,
      renderCell: (params) => <span>{`$${params.value.toFixed(2)}`}</span>,
    },
    {
      field: 'total_shipping',
      headerName: 'Total Shipping',
      width: 150,
      renderCell: (params) => <span>{`$${params.value.toFixed(2)}`}</span>,
    },
    { field: 'required_ship_date', headerName: 'Required Ship Date', width: 150 },
    {
      field: 'tracking_numbers',
      headerName: 'Tracking Numbers',
      width: 150,
      valueGetter: (params) => {
        const trackingNumbers = [];
        if (params.row?.shipments.length === 0) {
          return '';
        }
        // Iterate over shipments and packages to get tracking numbers
        params.row?.shipments.forEach((shipment) => {
          shipment.packages.forEach((pkg) => {
            trackingNumbers.push(pkg.tracking_number);
          });
        });
        return trackingNumbers.join(', ');
      },
    },
    { field: 'warehouse_customer_id', headerName: 'Warehouse Customer Id', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      align: 'right',
      getActions: (params) => [
        <GridActionsCellItem label="Update" onClick={() => setResourceToUpdate(params.row)} disabled={params.row.status === 'cancelled' || params.row.status === 'fulfilled'} showInMenu />,
        <GridActionsCellItem
          label="Fulfill"
          onClick={() => {
            setResourcesLoading(true);
            fulfillOrder(accessToken, apiKeys[0]?.api_key, params.row.id).then(
              () => {
                getResources(accessToken, apiKeys[0]?.api_key, selectedResource)
                  .then((data) => {
                    setRows(data);
                    setResourcesLoading(false);
                  });
              },
            );
          }}
          disabled={params.row.status === 'cancelled' || params.row.status === 'fulfilled'}
          showInMenu
        />,
        <GridActionsCellItem
          label="Cancel"
          onClick={() => {
            setResourcesLoading(true);
            cancelOrder(accessToken, apiKeys[0]?.api_key, params.row.id).then(
              () => {
                getResources(accessToken, apiKeys[0]?.api_key, selectedResource)
                  .then((data) => {
                    setRows(data);
                    setResourcesLoading(false);
                  });
              },
            );
          }}
          disabled={params.row.status === 'cancelled' || params.row.status === 'fulfilled'}
          showInMenu
        />,
      ],
    },
  ];

  const inboundShipmentColumns = [
    {
      field: 'id', headerName: 'ID', width: 90, hideable: false,
    },
    {
      field: 'purchase_order_number', headerName: 'Purchase Order Number', width: 150, hideable: false,
    },
    { field: 'created_date', headerName: 'Created Date', width: 150 },
    { field: 'updated_date', headerName: 'Updated Date', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'raw_status', headerName: 'Raw Status', width: 150 },
    { field: 'supplier', headerName: 'Supplier', width: 150 },
    { field: 'expected_arrival_date', headerName: 'Expected Arrival Date', width: 150 },
    { field: 'warehouse_id', headerName: 'Warehouse id', width: 150 },
    { field: 'warehouse_customer_id', headerName: 'Warehouse Customer Id', width: 150 },
    {
      field: 'actions', type: 'actions', flex: 1, align: 'right', getActions: (params) => [<GridActionsCellItem label="Update" onClick={() => setResourceToUpdate(params.row)} showInMenu disabled={params.row?.status !== 'open'} />],
    },
  ];

  const returnColumns = [
    {
      field: 'id', headerName: 'ID', width: 90, hideable: false,
    },
    { field: 'order_id', headerName: 'Original Order Id', width: 150 },
    { field: 'created_date', headerName: 'Created Date', width: 150 },
    { field: 'updated_date', headerName: 'Updated Date', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'raw_status', headerName: 'Raw Status', width: 150 },
    {
      field: 'actions', type: 'actions', flex: 1, align: 'right', getActions: (params) => [<GridActionsCellItem label="Update" onClick={() => setResourceToUpdate(params.row)} showInMenu disabled={params.row?.status === 'cancelled' || params.row?.status === 'received'} />],
    },
  ];

  const productColumns = [
    {
      field: 'id', headerName: 'ID', width: 90, hideable: false,
    },
    { field: 'sku', headerName: 'SKU', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'created_date', headerName: 'Created Date', width: 150 },
    { field: 'updated_date', headerName: 'Updated Date', width: 150 },
    { field: 'gtin', headerName: 'GTIN', width: 150 },
    { field: 'is_kit', headerName: 'Is Kit', width: 150 },
    { field: 'active', headerName: 'Active', width: 150 },
    { field: 'supplier', headerName: 'Supplier', width: 150 },
    { field: 'country_of_origin', headerName: 'Country of Origin', width: 150 },
    { field: 'harmonized_code', headerName: 'Harmonized Code', width: 150 },
    {
      field: 'actions', type: 'actions', flex: 1, align: 'right', getActions: (params) => [<GridActionsCellItem label="Update" onClick={() => setResourceToUpdate(params.row)} showInMenu />],
    },
  ];

  const inventoryColumns = [
    {
      field: 'id', headerName: 'ID', width: 90, hideable: false,
    },
    { field: 'sku', headerName: 'SKU', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'created_date', headerName: 'Created Date', width: 150 },
    { field: 'updated_date', headerName: 'Updated Date', width: 150 },
    { field: 'active', headerName: 'Active', width: 150 },
    { field: 'awaiting', headerName: 'Awaiting', width: 150 },
    { field: 'onhand', headerName: 'Onhand', width: 150 },
    { field: 'committed', headerName: 'Committed', width: 150 },
    { field: 'fulfillable', headerName: 'Fulfillable', width: 150 },
    { field: 'unfulfillable', headerName: 'Unfulfillable', width: 150 },
    { field: 'sellable', headerName: 'Sellable', width: 150 },
    { field: 'unsellable', headerName: 'Unsellable', width: 150 },
    {
      field: 'substitute_skus',
      headerName: 'Substitute SKUs',
      width: 150,
      valueGetter: (params) => (params.row.substitute_skus ? params.row.substitute_skus.join(', ') : ''),
    },
    {
      field: 'actions', type: 'actions', flex: 1, align: 'right', getActions: (params) => [<GridActionsCellItem label="Update" onClick={() => setResourceToUpdate(params.row)} showInMenu />],
    },
  ];

  const warehouseColumns = [
    {
      field: 'id', headerName: 'ID', width: 90, hideable: false,
    },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'code', headerName: 'Code', width: 150 },
    { field: 'created_date', headerName: 'Created Date', width: 150 },
    { field: 'updated_date', headerName: 'Updated Date', width: 150 },
    {
      field: 'address1',
      headerName: 'Address 1',
      width: 150,
      valueGetter: (params) => params.row.address?.address1,
    },
    {
      field: 'address2', headerName: 'Address 2', width: 150, valueGetter: (params) => params.row.address?.address2,
    },
    {
      field: 'address3', headerName: 'Address 3', width: 150, valueGetter: (params) => params.row.address?.address3,
    },
    {
      field: 'city', headerName: 'City', width: 150, valueGetter: (params) => params.row.address?.city,
    },
    {
      field: 'state', headerName: 'State', width: 150, valueGetter: (params) => params.row.address?.state,
    },
    {
      field: 'postal_code', headerName: 'Postal Code', width: 150, valueGetter: (params) => params.row.address?.postal_code,
    },
    {
      field: 'country', headerName: 'Country', width: 150, valueGetter: (params) => params.row.address?.country,
    },
  ];

  const [columns, setColumns] = useState(orderColumns);

  const handleCreateNewResource = () => {
    setCreateNewResourceModalOpen(false);
    getResources(accessToken, apiKeys[0]?.api_key, selectedResource).then((data) => {
      setRows(data);
    });
  };

  const handleCloseCreateUpdateModal = () => {
    setCreateNewResourceModalOpen(false);
    setResourceToUpdate(null);
  };

  const handleCreateUpdateError = (error) => {
    setCreateNewResourceModalOpen(false);
    setCreateUpdateError(error);
  };

  const handleOpenGenerateSandBoxModal = () => {
    setIsGenerateSandBoxModalOpen(true);
  };

  const handleCloseGenerateSandBoxModal = () => {
    setIsGenerateSandBoxModalOpen(false);
  };

  const handleGenerateSandbox = () => {
    setResourcesLoading(true);
    trackstar('internal/sandbox/generate-sandbox', 'POST').then(() => {
      setResourcesLoading(false);
      setSandboxRegenerated(true);
    });
  };

  useEffect(() => {
    if (organization) {
      trackstar('internal/api-keys').then((response) => {
        const { data } = response;
        setApiKeys(data);
      });
    }
  }, []);

  useEffect(() => {
    if (connectionData) {
      const sandboxConnection = connectionData.filter((connection) => connection.integration_name === 'sandbox');
      if (sandboxConnection.length === 0) {
        return;
      }
      const connectionId = sandboxConnection[0].connection_id;
      trackstar(`internal/access-token?connection_id=${connectionId}&integration_name=sandbox`)
        .then((response) => {
          setAccessToken(response.access_token);
        });
    }
  }, [connectionData]);

  useEffect(() => {
    if (accessToken) {
      setResourcesLoading(true);
      getResources(accessToken, apiKeys[0]?.api_key, selectedResource).then((data) => {
        setRows(data);
        if (selectedResource === 'orders') {
          setColumns(orderColumns);
        } else if (selectedResource === 'inbound-shipments') {
          setColumns(inboundShipmentColumns);
        } else if (selectedResource === 'returns') {
          setColumns(returnColumns);
        } else if (selectedResource === 'products') {
          setColumns(productColumns);
        } else if (selectedResource === 'inventory') {
          setColumns(inventoryColumns);
        } else if (selectedResource === 'warehouses') {
          setColumns(warehouseColumns);
        }
        setResourcesLoading(false);
      });
    }
  }, [accessToken, selectedResource]);

  useEffect(
    () => {
      if (resourceToUpdate) {
        setCreateNewResourceModalOpen(true);
      }
    },
    [resourceToUpdate],
  );

  return (
    <div className="flex flex-col gap-8 p-5 w-10/12 h-screen">
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold leading-9">
          Sandbox
        </h1>
        <button
          type="button"
          style={{
            border: '1px solid #2a6faf', borderRadius: '8px', color: '#2a6faf', background: null, padding: '10px 20px',
          }}
          className="hover:bg-trackstarBlueHover"
          onClick={() => handleOpenGenerateSandBoxModal(true)}
        >
          Regenerate Sandbox Data
        </button>
      </div>
      {accessToken && (
        <div className="flex justify-between">
          <select onChange={(event) => setSelectedResource(event.target.value)} className="mt-1 px-3 py-2 border rounded-lg focus:ring focus:ring-blue-200">
            <option value="orders">Orders</option>
            <option value="inbound-shipments">Inbound Shipments</option>
            <option value="returns">Returns</option>
            <option value="products">Products</option>
            <option value="inventory">Inventory</option>
            <option value="warehouses">Warehouses</option>
          </select>
          {selectedResource !== 'warehouses' && (
          <button
            type="button"
            style={{
              border: '1px solid #2a6faf', borderRadius: '8px', color: '#2a6faf', background: null, padding: '10px 20px',
            }}
            className="hover:bg-trackstarBlueHover"
            onClick={() => setCreateNewResourceModalOpen(true)}
          >
            New
            {' '}
            {selectedResource === 'orders' ? 'Order' : selectedResource === 'inbound-shipments' ? 'Inbound Shipment' : selectedResource === 'returns' ? 'Return' : selectedResource === 'inventory' ? 'Inventory Item' : 'Product'}
          </button>
          )}
        </div>
      )}
      <DataGridPro
        rows={rows}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: NoRowsOverLay,
        }}
        loading={resourcesLoading}
        initialState={{
          columns: {
            columnVisibilityModel: {
              reference_id: false,
              updated_date: false,
              raw_status: false,
              trading_partner: false,
              shipping_method: false,
              invoice_currency_code: false,
              total_tax: false,
              total_discount: false,
              total_shipping: false,
              required_ship_date: false,
              warehouse_customer_id: false,
              expected_arrival_date: false,
              substitute_skus: false,
              address3: false,
            },
          },
        }}
        pagination
        autoPageSize
        pageSizeOptions={[]}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F5F5F5',
            borderTop: '1px solid #E5E7EB',
            borderRadius: '0px !important',
            fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            fontWeight: '400',
            fontSize: '13px',
            color: '#6b7280',
          },
          '& .column-header-no-border .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          borderRadius: '0px !important',
          '& .MuiDataGrid-cell': {
            fontSize: '13px',
            fontWeight: '400',
            fontFamily: 'ui-sans-serif, system-ui, sans-serif',
          },
        }}
      />
      {selectedResource === 'orders' && (
      <CreateUpdateOrderModal
        isOpen={createNewResourceModalOpen}
        onClose={handleCloseCreateUpdateModal}
        accessToken={accessToken}
        apiKey={apiKeys[0]?.api_key}
        handleCreateNewResource={handleCreateNewResource}
        existingOrder={resourceToUpdate}
        handleCreateUpdateError={handleCreateUpdateError}
      />
      )}
      {/* {selectedResource === 'orders' && (
        <CreateUpdateResource
          isOpen={createNewResourceModalOpen}
          onClose={handleCloseCreateUpdateModal}
          accessToken={accessToken}
          apiKey={apiKeys[0]?.api_key}
          selectedResource={selectedResource}
          handleCreateNewResource={handleCreateNewResource}
          existingResource={resourceToUpdate}
          handleCreateUpdateError={handleCreateUpdateError}
        />
      )} */}
      {selectedResource === 'inbound-shipments' && (
      <CreateUpdateInboundShipmentModal
        isOpen={createNewResourceModalOpen}
        onClose={handleCloseCreateUpdateModal}
        accessToken={accessToken}
        apiKey={apiKeys[0]?.api_key}
        handleCreateNewResource={handleCreateNewResource}
        existingShipment={resourceToUpdate}
        handleCreateUpdateError={handleCreateUpdateError}
      />
      )}
      {selectedResource === 'returns' && (
      <CreateUpdateReturnModal
        isOpen={createNewResourceModalOpen}
        onClose={handleCloseCreateUpdateModal}
        accessToken={accessToken}
        apiKey={apiKeys[0]?.api_key}
        handleCreateNewResource={handleCreateNewResource}
        existingReturn={resourceToUpdate}
        handleCreateUpdateError={handleCreateUpdateError}
      />
      )}
      {selectedResource === 'products' && (
      <CreateNewProductModal
        isOpen={createNewResourceModalOpen}
        onClose={handleCloseCreateUpdateModal}
        accessToken={accessToken}
        apiKey={apiKeys[0]?.api_key}
        handleCreateNewResource={handleCreateNewResource}
        existingProduct={resourceToUpdate}
        handleCreateUpdateError={handleCreateUpdateError}
      />
      )}
      {selectedResource === 'inventory' && (
      <CreateUpdateInventoryModal
        isOpen={createNewResourceModalOpen}
        onClose={handleCloseCreateUpdateModal}
        accessToken={accessToken}
        apiKey={apiKeys[0]?.api_key}
        handleCreateNewResource={handleCreateNewResource}
        existingInventory={resourceToUpdate}
        handleCreateUpdateError={handleCreateUpdateError}
      />
      )}
      {createUpdateError && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => setCreateUpdateError(null)} tabIndex={-1} aria-hidden="true" />
        <div className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-content">
          <h2 className="text-2xl font-semibold pt-1 pb-0">
            {selectedResource}
            {' '}
            was not
            {' '}
            {resourceToUpdate ? 'updated' : 'created'}
          </h2>
          <div>
            Error(s):
            {' '}
            {createUpdateError}
          </div>
          <div className="flex flex-grwo justify-end pt-4">
            <button
              type="button"
              onClick={() => setCreateUpdateError(null)}
              className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      )}
      <div className="flex justify-center items-center">
        <GenerateSandboxModal
          isOpen={isGenerateSandBoxModalOpen}
          onClose={handleCloseGenerateSandBoxModal}
          onConfirm={handleGenerateSandbox}
          loading={resourcesLoading}
          completed={sandboxRegenerated}
        />
      </div>
    </div>
  );
}

export default Sandbox;
