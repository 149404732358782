import React from 'react';
import { GridColumnMenu } from '@mui/x-data-grid-pro';

export default function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
      }}
    />
  );
}
