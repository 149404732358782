import React, { useEffect, useState } from 'react';
import {
  getNewProductDefaults, createNewResource, updateProduct,
} from './SandboxApi';

function CreateNewProductModal({
  isOpen, onClose, accessToken, apiKey, handleCreateNewResource, existingProduct,
  handleCreateUpdateError,
}) {
  const [productData, setProductData] = useState(getNewProductDefaults());
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setProductData(getNewProductDefaults());
    setLoading(false);
    onClose();
  };

  const handleCreateUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    if (existingProduct) {
      updateProduct(accessToken, apiKey, existingProduct.id, productData)
        .then((response) => {
          handleCreateNewResource(response);
          handleClose();
        });
    } else {
      createNewResource(accessToken, apiKey, 'products', productData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (existingProduct) {
      setProductData(existingProduct);
    } else {
      setProductData(getNewProductDefaults());
    }
  }, [existingProduct]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => handleClose()} tabIndex={-1} aria-hidden="true" />
      <form className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-fit" onSubmit={handleCreateUpdate}>
        <h2 className="text-2xl font-semibold pt-1 pb-0">
          {existingProduct ? 'Update' : 'Create New'}
          {' '}
          Product
        </h2>
        <div className="grid grid-cols-3 gap-2" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div key="name">
            <span htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </span>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={productData.name}
              onChange={(e) => setProductData({ ...productData, name: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="sku">
            <span htmlFor="sku" className="block text-sm font-medium text-gray-700">
              SKU
            </span>
            <input
              type="text"
              id="sku"
              name="sku"
              value={productData.sku}
              onChange={(e) => setProductData({ ...productData, sku: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="gtin">
            <span htmlFor="gtin" className="block text-sm font-medium text-gray-700">
              GTIN
            </span>
            <input
              type="text"
              id="gtin"
              name="gtin"
              value={productData.gtin}
              onChange={(e) => setProductData({ ...productData, gtin: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
        </div>
        <div className="flex flex-grow justify-end pt-4">
          <button
            type="button"
            onClick={() => onClose()}
            className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="self-end bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            disabled={loading}
          >
            {existingProduct ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default CreateNewProductModal;
