import React, { useEffect, useState } from 'react';
import { AppPortal } from 'svix-react';
import GridLoader from 'react-spinners/GridLoader';
import { useFetch } from './api';

function Webhooks() {
  const trackstar = useFetch();
  const [url, setUrl] = useState('');
  useEffect(() => {
    trackstar('svix/app-portal').then((data) => {
      setUrl(data.url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const spinner = (
    <GridLoader
      color="#C9B0F0"
      size={20}
      cssOverride={{
        position: 'absolute',
        top: '30%',
        left: '50%',
      }}
    />
  );

  // if not url return loading else return AppPortal
  return (
    <div className="flex flex-col gap-10 p-5 w-10/12">
      <h1 className="text-3xl font-semibold leading-9">
        Webhooks
      </h1>

      <div className="w-full rounded-lg p-2 border h-full">
        {url ? <AppPortal url={url} loadingIndicator={spinner} style={{ height: '100%' }} /> : spinner}
      </div>
    </div>
  );
}

export default Webhooks;
